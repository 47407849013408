@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Oswald", sans-serif;
}

.clip-path {
  clip-path: circle(42% at 50% 50%);
}

.bg-shops {
  background-position: center;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1584679109354-e06f8dc2a8e3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80");
}

@media screen and (min-width: 1024px) {
  .invert-svg:hover img {
    filter: invert(1);
  }
}
